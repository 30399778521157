export default function formatISOdate(ISOdate) {
    const date = new Date(ISOdate);

    return ("0" + date.getDate()).slice(-2) + ". " +
        ("0" + (date.getMonth() + 1)).slice(-2) + ". " +
        date.getFullYear() + " " +

        ("0" + date.getHours()).slice(-2) + ":" +
        ("0" + date.getMinutes()).slice(-2) + ":" +
        ("0" + date.getSeconds()).slice(-2);
}