// Inspired by: https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/sign-in

import React, { Component } from "react";

import { connect } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";

class Profile extends Component {
    render() {
        return (
            <Container className="profileContainer" component="main" maxWidth="xs">

                <table className="profileTable">
                    <tbody>
                        <tr><td colSpan="2"><Avatar className="avatar" /></td></tr>
                        <tr><td colSpan="2"><h2>{this.props.user.fullname}</h2></td></tr>
                        <tr><td><h3>Uživatelské jméno:</h3></td><td><h3>{this.props.user.username}</h3></td></tr>
                        <tr><td><h3>Role:</h3></td><td><h3>{this.props.user.role}</h3></td></tr>
                    </tbody>
                </table>

            </Container >
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Profile);