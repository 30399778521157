import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import store from "./store";
import { Provider } from "react-redux";
import { tokenChange } from "./actions";

import socket from "./utils/socket.io";

import cookie from "react-cookies";

function updateToken() {
  let accessToken = cookie.load("accessToken");
  if (typeof accessToken === "undefined") {
    accessToken = null;
  }

  const state = store.getState()
  if (state.token !== accessToken) {
    store.dispatch(tokenChange(accessToken));

    if (state.user === null && accessToken !== null) {
      socket.emit("login:token", accessToken);
    }
  }
}

window.onpopstate = (e) => {
  window.location.reload();
}

updateToken();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
