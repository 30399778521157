// Inspired by: https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/menus/SimpleMenu.js

import React, { Component } from "react";

import { connect } from "react-redux";
import { load } from "../../actions";

import socket from "../../utils/socket.io";
import formatISOdate from "../../utils/formatISOdate";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from '@material-ui/core/CircularProgress';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

function TestsActual({ dispatch, data, loading }) {
    const evaluated = data?.evaluated ?? [];
    const ready = data?.ready ?? [];
    const future = data?.future ?? [];

    return (<div>
        {evaluated.length === 0 && ready.length === 0 && future.length === 0 ? <h1>Nejsou dostupné žádné testy</h1> : null}
        { evaluated.length > 0 ?
            <div>
                <h2>Nově ohodnocené</h2>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Název</TableCell>
                                <TableCell align="left">Dostupný od</TableCell>
                                <TableCell align="left">Dostupný do</TableCell>
                                <TableCell align="left">Maximum bodů</TableCell>
                                <TableCell align="left">Dosaženo bodů</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {evaluated.map((test) => (<Test type="actual" dispatch={dispatch} loading={loading} key={test.id} id={test.id} name={test.name} description={test.description}
                                start={test.availability_from} end={test.availability_to} points={test.points} maxPoints={test.points_max} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
            </div> : null}

        { ready.length > 0 ?
            <div>
                <h2>Dostupné</h2>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Název</TableCell>
                                <TableCell align="left">Dostupný od</TableCell>
                                <TableCell align="left">Dostupný do</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ready.map((test) => (<Test type="ready" dispatch={dispatch} loading={loading} key={test.id} id={test.id} name={test.name} description={test.description}
                                start={test.availability_from} end={test.availability_to} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
            </div> : null}

        { future.length > 0 ?
            <div>
                <h2>Nadcházející</h2>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Název</TableCell>
                                <TableCell align="left">Dostupný od</TableCell>
                                <TableCell align="left">Dostupný do</TableCell>
                                <TableCell align="center">Potvrzeno</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {future.map((test) => (<Test type="future" dispatch={dispatch} loading={loading} key={test.id} id={test.id} name={test.name} description={test.description}
                                start={test.availability_from} end={test.availability_to} confirmed={test.confirmed} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div> : null}

    </div>
    );
}

class Test extends Component {
    constructor() {
        super();
        this.state = { open: false }
    }

    fillButtonHandle = () => {
        this.props.dispatch(load("tests:fill:" + this.props.id));
        socket.emit("view:switch", "/test/fill", { testId: this.props.id });
    }

    historyButtonHandle = () => {
        this.props.dispatch(load("tests:history:" + this.props.id));
        socket.emit("view:switch", "/test/view", { testId: this.props.id, studentId: this.props.userId });
    }

    render() {
        const start = this.props.start === null ? null : formatISOdate(this.props.start);
        const end = this.props.end === null ? null : formatISOdate(this.props.end);
        return (
            [
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ open: !this.state.open })}>
                            {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">{this.props.name}</TableCell>
                    <TableCell align="left">{start}</TableCell>
                    <TableCell align="left">{end}</TableCell>
                    {this.props.type === "actual" ? [
                        <TableCell align="left">{this.props.maxPoints}</TableCell>,
                        <TableCell align="left"><b>{this.props.points}</b></TableCell>]
                        : null}
                    <TableCell align="center">
                        {this.props.type === "ready" ?
                            <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                                onClick={this.fillButtonHandle}>
                                {this.props.loading === "tests:fill:" + this.props.id ? <CircularProgress size={24} /> : "Vyplnit"}
                            </Button>
                            : null
                        }
                        {this.props.type === "future" ?
                            this.props.confirmed ? <CheckIcon /> : <ClearIcon />
                            : null
                        }
                        {this.props.type === "actual" ?
                            <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                                onClick={this.historyButtonHandle}>
                                {this.props.loading === "tests:history:" + this.props.id ? <CircularProgress size={24} /> : "Zobrazit"}
                            </Button>
                            : null}

                    </TableCell>

                </TableRow>,
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={this.state.open}>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        {this.props.description}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            ]
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        data: state.data
    }
}

export default connect(mapStateToProps)(TestsActual);