import { combineReducers } from "redux";
import connection from "./connection";
import loading from "./loading";
import token from "./token";
import user from "./user";
import data from "./data";
import view from "./view";
import loginAlert from "./loginAlert";
import snackbar from "./snackbar";

export default combineReducers({
    connection,
    loading,
    token,
    user,
    data,
    view,
    loginAlert,
    snackbar
})
