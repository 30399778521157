import { LOGIN_ERROR, TOKEN_CHANGE, TOKEN_UPDATE, USER_UPDATE } from "../actions";

import cookie from "react-cookies";

export default function token(state = null, action) {
    switch (action.type) {
        case TOKEN_CHANGE:
            return action.token

        case TOKEN_UPDATE:
            cookie.remove("accessToken", { path: "/" });
            if (action.token != null) {
                cookie.save("accessToken", action.token, { path: "/", maxAge: action.expiration });
            }
            return action.token

        case LOGIN_ERROR:
            cookie.remove("accessToken", { path: "/" });
            return null

        case USER_UPDATE:
            if (action.user === null) {
                // Logout
                cookie.remove("accessToken", { path: "/" });
                return null
            }
            return state

        default:
            return state
    }
}