import { SET_VIEW, LOAD, LOAD_ERROR, LOGIN_ERROR, RAISE_SNACKBAR, USER_UPDATE, CONNECT } from "../actions";

export default function loading(state = "dashboard", action) {
    switch (action.type) {
        case CONNECT:
            return "dashboard"

        case LOAD:
            return action.callerId;

        case USER_UPDATE:
            if (action.user === null) {
                // Logout - loading for next login
                return "dashboard";
            }
            return state;

        case LOAD_ERROR:
            return null;

        case SET_VIEW:
            if (action.view === "/") {
                // No view - show loading
                return "dashboard";
            }
            return null;

        case LOGIN_ERROR:
            return "dashboard";

        case RAISE_SNACKBAR:
            return null;

        default:
            return state;
    }
}
