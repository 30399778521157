import { CONNECT, LOGIN_ERROR, LOGIN_ERROR_CLOSE } from "../actions";

export default function loginAlert(state = null, action) {
    switch (action.type) {
        case CONNECT:
            return null

        case LOGIN_ERROR:
            return action.reason

        case LOGIN_ERROR_CLOSE:
            return null

        default:
            return state
    }
}