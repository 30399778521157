import { CONNECT, SET_VIEW } from "../actions";

export default function data(state = {}, action) {
    switch (action.type) {
        case CONNECT:
            return {}

        case SET_VIEW:
            if (state?.evaluated && action?.data?.evaluated) {
                // Don't throw away evaluated tests on data update
                return {
                    ...action.data,
                    evaluated: [...state.evaluated, ...action.data.evaluated]
                }
            }

            return action.data;

        default:
            return state
    }
}
