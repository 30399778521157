import React from "react";

import { connect } from "react-redux";
import { load } from "../actions";

import socket from "../utils/socket.io";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

function NavButtonHandler(dispatch, path) {
    dispatch(load("dashboard"));
    socket.emit("view:switch", path, {});
}

function NavButton({ dispatch, text, path, children, view, loading }) {
    return (
        <ListItem button onClick={() => NavButtonHandler(dispatch, path)} selected={view === path} disabled={loading ? true : false} >
            <ListItemIcon>{children}</ListItemIcon>
            <ListItemText primary={text} />
        </ListItem>)
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        view: state.view
    }
}

export default connect(mapStateToProps)(NavButton);