// Inspired by: https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/drawers/ClippedDrawer.js

import React from "react";

import { connect } from "react-redux";
import { load, closeSnackbar } from "../actions";

import socket from "../utils/socket.io";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListSubheader from '@material-ui/core/ListSubheader';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import AssignmentIcon from "@material-ui/icons/Assignment";
import HistoryIcon from "@material-ui/icons/History";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import GradeIcon from "@material-ui/icons/Grade";
import CreateIcon from "@material-ui/icons/Create";
import PersonIcon from "@material-ui/icons/Person";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


import TestsActual from "./dashboardContent/TestsActual";
import TestsHistory from "./dashboardContent/TestsHistory";
import TestsRegistration from "./dashboardContent/TestsRegistration";
import TestsConfirmation from "./dashboardContent/TestsConfirmation";
import TestsGrade from "./dashboardContent/TestsGrade";
import TestsManage from "./dashboardContent/TestsManage";
import UsersActual from "./dashboardContent/UsersActual";
import UsersManage from "./dashboardContent/UsersManage";
import UsersPromote from "./dashboardContent/UsersPromote";

import TestFill from "./dashboardContent/TestFill";
import TestGrade from "./dashboardContent/TestGrade";
import TestView from "./dashboardContent/TestView";

import NavButton from "../components/NavButton";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function onCloseSnackbar(dispatch) {
  dispatch(closeSnackbar());
}

function Dashboard({ dispatch, loading, view, snackbar, user }) {
  const classes = useStyles();

  let content = null;
  switch (view) {
    case "/test/fill":
      content = <TestFill />;
      break;

    case "/test/view":
      content = <TestView />;
      break;

    case "/test/grade":
      content = <TestGrade />;
      break;

    case "/tests/actual":
      content = <TestsActual />;
      break;

    case "/tests/history":
      content = <TestsHistory />;
      break;

    case "/tests/registration":
      content = <TestsRegistration />;
      break;

    case "/tests/confirmation":
      content = <TestsConfirmation />;
      break;

    case "/tests/grade":
      content = <TestsGrade />;
      break;

    case "/tests/manage":
      content = <TestsManage />;
      break;

    case "/users/actual":
      content = <UsersActual />;
      break;

    case "/users/manage":

      content = user?.permissions.includes("user_manage") ? <UsersManage /> : <UsersPromote />;
      break;

    default:
      break;
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <h3>Testinator</h3>
        </Toolbar>
        {loading === "dashboard" ? <LinearProgress /> : null}
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List subheader={<ListSubheader>Testy</ListSubheader>}>
            <NavButton text="Aktuální" path="/tests/actual">
              <AssignmentIcon />
            </NavButton>
            <NavButton text="Historie" path="/tests/history">
              <HistoryIcon />
            </NavButton>
            <NavButton text="Registrace" path="/tests/registration">
              <AssignmentTurnedInIcon />
            </NavButton>
            {user?.permissions.includes("test_confirm") ?
              <NavButton text="Potrzování registrací" path="/tests/confirmation">
                <PlaylistAddCheckIcon />
              </NavButton> : null}
            {user?.permissions.includes("test_evaluate") ?
              <NavButton text="Opravování" path="/tests/grade">
                <GradeIcon />
              </NavButton> : null}
            {user?.permissions.includes("test_manage") ?
              <NavButton text="Správa testů" path="/tests/manage">
                <CreateIcon />
              </NavButton> : null}
          </List>

          <List subheader={<ListSubheader>Uživatel</ListSubheader>}>
            <NavButton text={user?.fullname ?? "Profil"} path="/users/actual">
              <PersonIcon />
            </NavButton>
            {user?.permissions.includes("user_list") ?
              <NavButton text="Správa uživatelů" path="/users/manage">
                <SupervisorAccountIcon />
              </NavButton> : null}
            <ListItem button onClick={() => logout(dispatch)} disabled={loading ? true : false}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary={"Odhlásit se"} />
            </ListItem>
          </List>

        </div>
      </Drawer>

      <main className={classes.content}>
        <Toolbar />
        {content}
      </main>
      {snackbar === null ?
        null :
        <Snackbar open onClose={closeSnackbar}>
          <Alert severity={snackbar.severity} elevation={6} variant="filled" onClose={() => onCloseSnackbar(dispatch)}>
            {snackbar.text}
          </Alert>
        </Snackbar>
      }

    </div >
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    snackbar: state.snackbar,
    view: state.view,
    loading: state.loading
  }
}

function logout(dispatch) {
  dispatch(load("dashboard"));
  socket.emit("logout");
}

export default connect(mapStateToProps)(Dashboard);