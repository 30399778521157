import { SET_VIEW, RAISE_SNACKBAR, CLOSE_SNACKBAR, CONNECT } from "../actions";

export default function snackbar(state = null, action) {
    switch (action.type) {
        case CONNECT:
            return null

        case SET_VIEW:
            // FIXME: View data update may hide snackbar too soon
            return null

        case RAISE_SNACKBAR:
            return {
                severity: action.severity,
                text: action.text
            }

        case CLOSE_SNACKBAR:
            return null

        default:
            return state
    }
}