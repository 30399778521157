import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

export default function Connecting() {
    return (
        <div className="connecting">
            <h1>Připojuji se k serveru...</h1>
            <CircularProgress className="connectingProgress" size={250} />
        </div>
    );
}