import { DISCONNECT, USER_UPDATE, LOGIN_ERROR, CONNECT } from "../actions";

export default function user(state = null, action) {
    switch (action.type) {
        case CONNECT:
            return null

        case DISCONNECT:
            return null

        case LOGIN_ERROR:
            return null;

        case USER_UPDATE:
            return action.user;

        default:
            return state
    }
}