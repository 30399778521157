// Inspired by: https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/menus/SimpleMenu.js

import React, { Component } from "react";

import { connect } from "react-redux";
import { load } from "../../actions";

import socket from "../../utils/socket.io";
import formatISOdate from "../../utils/formatISOdate";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from '@material-ui/core/CircularProgress';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

function TestsGrade({ dispatch, data, loading }) {
    const tests = data?.grade ?? [];

    return tests.length === 0 ? <h1>Nejsou dostupné žádné testy</h1> :
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="left">Název</TableCell>
                        <TableCell align="left">Dostupný od</TableCell>
                        <TableCell align="left">Dostupný do</TableCell>
                        <TableCell align="left">Jméno a přijmení</TableCell>
                        <TableCell align="left">Uživatelské jméno</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tests.map((test) => (<Test dispatch={dispatch} loading={loading} key={test.id} id={test.id} name={test.name} description={test.description}
                        userId={test.studentId} start={test.availability_from} end={test.availability_to}
                        username={test.username} fullname={test.fullname} />))}
                </TableBody>
            </Table>
        </TableContainer>
}

class Test extends Component {
    constructor() {
        super();
        this.state = { open: false }
    }

    gradeButtonHandle = () => {
        this.props.dispatch(load("tests:grade:" + this.props.id + this.props.userId));
        socket.emit("view:switch", "/test/grade", { testId: this.props.id, studentId: this.props.userId });
    }

    render() {
        const start = this.props.start === null ? null : formatISOdate(this.props.start);
        const end = this.props.end === null ? null : formatISOdate(this.props.end);
        return (
            [
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ open: !this.state.open })}>
                            {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">{this.props.name}</TableCell>
                    <TableCell align="left">{start}</TableCell>
                    <TableCell align="left">{end}</TableCell>
                    <TableCell align="left">{this.props.fullname}</TableCell>
                    <TableCell align="left">{this.props.username}</TableCell>
                    <TableCell align="center">
                        <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                            onClick={this.gradeButtonHandle}>
                            {this.props.loading === "tests:grade:" + this.props.id + this.props.userId ? <CircularProgress size={24} /> : "Opravit"}
                        </Button>
                    </TableCell>

                </TableRow>,
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={this.state.open}>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        {this.props.description}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            ]
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        data: state.data
    }
}

export default connect(mapStateToProps)(TestsGrade);