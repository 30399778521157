// Inspired by: https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/sign-in

import React, { Component } from "react";

import { connect } from "react-redux";
import { load, raiseSnackbar } from "../actions";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import CircularProgress from '@material-ui/core/CircularProgress';

import socket from "../utils/socket.io";

class ChangePassword extends Component {
    constructor() {
        super();
        this.state = { matchError: false };
    }

    passwordChangeHandle(e) {
        e.preventDefault();

        if (this.newPassword.value !== this.newPasswordCheck.value) {
            this.props.dispatch(raiseSnackbar("error","Hesla jsou rozdílná"))
            return;
        }

        this.props.dispatch(load("changePassword"));

        socket.emit(
            "user:edit:password",
            this.password.value,
            this.newPassword.value)

        this.form.reset();
    }

    matchClose() {
        this.setState({ matchError: false });
    }

    render() {
        return (
            <Container className="userPasswordChange" component="main" maxWidth="xs">
                <h2>Změna hesla</h2>
                <form onSubmit={(e) => this.passwordChangeHandle(e)} ref={form => this.form = form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Aktuální heslo"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        inputRef={password => this.password = password}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="Nové heslo"
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        inputRef={newPassword => this.newPassword = newPassword}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="newPasswordCheck"
                        label="Nové heslo pro kontrolu"
                        type="password"
                        id="newPasswordCheck"
                        autoComplete="new-password"
                        inputRef={newPasswordCheck => this.newPasswordCheck = newPasswordCheck}
                    />
                    {this.props.loading === "changePassword"
                        ?
                        <Button
                            disabled
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"><CircularProgress size={24} />
                        </Button>
                        :
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"> Změnit
                    </Button>}
                </form>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

export default connect(mapStateToProps)(ChangePassword)