import React, { Component } from "react";

import { connect } from "react-redux";
import { load, raiseSnackbar } from "../actions";

import socket from "../utils/socket.io";

import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { DropzoneArea } from 'material-ui-dropzone';

import AddIcon from '@material-ui/icons/Add';

function asyncFileRead(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}
class CreateTest extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            groupCount: 1
        };
        this.groups = [];
    }


    handleClickOpen() {
        this.setState({ ...this.state, open: true });
    };

    async handleClose(dispatch, created) {
        if (created) {
            if (this.name.value === "") {
                dispatch(raiseSnackbar("error", "Nezadán název testu"));
                return;
            }
            let test = {
                name: this.name.value,
                description: this.description.value,
                groups: []
            };

            console.log("Creating test - groups");
            console.log(this.groups);
            for (let i in this.groups) {
                let group = this.groups[i];
                if (group.count.value === "" || parseInt(group.count.value) < 1) {
                    dispatch(raiseSnackbar("error", "Počet generovaných otázek skupiny " + (parseInt(i) + 1) + " musí být větší než 0"));
                    return;
                }
                let groupOut = {
                    priority: null, // parseInt(i), // Otázky podle zadání mají být v náhodném pořadí
                    count: parseInt(group.count.value),
                    questions: []
                }
                for (let j in group.questions) {
                    let question = group.questions[j];
                    if (question.name.value === "") {
                        dispatch(raiseSnackbar("error", "Prázdná otázka " + (parseInt(j) + 1) + " skupiny " + (parseInt(i) + 1)));
                        return;
                    }
                    if (question.maxPoints.value === "" || parseInt(question.maxPoints.value) < 0) {
                        dispatch(raiseSnackbar("error", "Maximální počet bodů otázky " + (parseInt(j) + 1) + " skupiny " + (parseInt(i) + 1) + " nemůže být záporný"));
                        return;
                    }
                    let questionOut = {
                        name: question.name.value,
                        description: question.description.value,
                        maxPoints: parseInt(question.maxPoints.value),
                        img: question.img !== null ? await asyncFileRead(question.img) : null,
                        type: question.state.type,
                        options: []
                    }

                    for (let k in question.options) {
                        let option = question.options[k];
                        if (option.text.value === "") {
                            dispatch(raiseSnackbar("error", "Prázdná možnost " + (parseInt(k) + 1) + " otázky " + (parseInt(j) + 1) + " skupiny " + (parseInt(i) + 1)));
                            return;
                        }
                        let optionOut = {
                            text: option.text.value,
                            points: option.points.value === "" ? 0 : parseInt(option.points.value),
                        }
                        questionOut.options.push(optionOut);
                    }
                    groupOut.questions.push(questionOut);
                }
                test.groups.push(groupOut);
            }
            this.props.dispatch(load("dashboard"));
            socket.emit("tests:create", test);
        }
        this.setState({ open: false, groupCount: 1 });
    };

    handleCreateGroup() {
        this.setState({ ...this.state, groupCount: this.state.groupCount + 1 });
    }

    handleDeleteGroup() {
        this.setState({ ...this.state, groupCount: this.state.groupCount > 1 ? this.state.groupCount - 1 : this.state.groupCount });
    }

    render() {
        let content = [];

        this.groups = new Array(this.state.groupCount);
        for (let i = 0; i < this.state.groupCount; i++) {
            content.push(<Group key={i} keyCopy={i} ref={group => this.groups[i] = group} />);
        }
        return (<div>
            <Fab color="primary" className="fab" disabled={this.props.loading ? true : false}
                onClick={() => { this.handleClickOpen() }}><AddIcon /></Fab>
            <Dialog open={this.state.open} onClose={() => this.handleClose(this.props.dispatch, false)}>
                <DialogTitle>Tvorba nového testu</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Název testu"
                        name="testName"
                        autoFocus
                        inputRef={name => this.name = name}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        multiline
                        label="Popis testu"
                        name="testDescription"
                        inputRef={description => this.description = description}
                    />
                    {content}
                    <div className="groupButtons">
                        <Button
                            className="dialogButton"
                            variant="contained"
                            color="secondary"
                            disabled={this.props.loading ? true : false}
                            onClick={() => { this.handleDeleteGroup() }}>
                            Smazat skupinu
                    </Button>
                        <Button
                            className="dialogButton"
                            variant="contained"
                            color="primary"
                            disabled={this.props.loading ? true : false}
                            onClick={() => { this.handleCreateGroup() }}>
                            Přidat skupinu
                    </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.props.loading ? true : false} onClick={() => this.handleClose(this.props.dispatch, false)} color="primary">
                        Zrušit
                </Button>
                    <Button disabled={this.props.loading ? true : false} onClick={() => this.handleClose(this.props.dispatch, true)} color="primary">
                        {this.props.loading ? <CircularProgress size={24} /> : "Potvrdit"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
        )
    }
}

class Group extends Component {
    constructor() {
        super();
        this.state = { questionCount: 1 }
        this.questions = [];
    }

    handleCreateQuestion() {
        this.setState({ questionCount: this.state.questionCount + 1 });
    }

    handleDeleteQuestion() {
        this.setState({ questionCount: this.state.questionCount > 1 ? this.state.questionCount - 1 : this.state.questionCount });
    }

    render() {
        let content = [];

        this.questions = new Array(this.state.questionCount);
        for (let i = 0; i < this.state.questionCount; i++) {
            content.push(<Question key={i} keyCopy={i} ref={question => this.questions[i] = question} />);
        }
        return (
            <Accordion >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Skupina otázek {this.props.keyCopy + 1}
                </AccordionSummary>
                <AccordionDetails>
                    <div >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            number
                            type="number"
                            label="Počet pro výběr"
                            name="count"
                            inputRef={count => this.count = count}
                        />
                        {content}
                        <div>
                            <Button className="dialogButton" variant="contained" color="secondary" disabled={this.props.loading ? true : false}
                                onClick={() => { this.handleDeleteQuestion() }}>Smazat otázku</Button>
                            <Button className="dialogButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                                onClick={() => { this.handleCreateQuestion() }}>Přidat otázku</Button>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }
}

class Question extends Component {
    constructor() {
        super();
        this.img = null;
        this.options = [];
        this.state = {
            type: "singleline",
            optionsCount: 2
        }
    }

    handleChange = (event) => {
        this.setState({ ...this.state, type: event.target.value });
    };

    setOptionsCount = (event) => {
        this.setState({ ...this.state, optionsCount: parseInt(event.target.value) });
    }

    render() {
        let content = [];

        this.options = new Array(this.state.optionsCount);
        for (let i = 0; i < this.state.optionsCount; i++) {
            content.push(<Option key={i} ref={option => this.options[i] = option} />);
        }
        return (
            <Accordion >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Otázka {this.props.keyCopy + 1}
                </AccordionSummary>
                <AccordionDetails>
                    <div >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Typ odpovědi</FormLabel>
                            <RadioGroup row value={this.state.type} onChange={this.handleChange}>
                                <FormControlLabel value="singleline" control={<Radio color="primary" />} label="Jednořádková" />
                                <FormControlLabel value="multiline" control={<Radio color="primary" />} label="Víceřádková" />
                                <FormControlLabel value="options" control={<Radio color="primary" />} label="Možnosti" />
                            </RadioGroup>
                        </FormControl>
                        <Grid container spacing={3}>
                            <Grid item xs={9}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Otázka"
                                    name="questionName"
                                    autoFocus
                                    inputRef={name => this.name = name}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="number"
                                    label="Max bodů"
                                    name="maxPoints"
                                    inputRef={maxPoints => this.maxPoints = maxPoints}
                                />
                            </Grid>
                        </Grid>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            multiline
                            fullWidth
                            label="Popis"
                            name="questionDescription"
                            inputRef={description => this.description = description}
                        />

                        {this.state.type === "options" ? [
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        value={this.state.optionsCount}
                                        type="number"
                                        label="Počet možností"
                                        name="optionsCount"
                                        onChange={this.setOptionsCount}
                                        inputRef={optionsCount => this.optionsCount = optionsCount}
                                    />
                                </Grid>
                                <Grid item xs={9} />
                                {content}
                            </Grid>,
                            <br />]

                            : null}

                        <DropzoneArea acceptedFiles={['image/*']} dropzoneText={"Obrázek nahrajete přetáhnutím nebo vyberete kliknutím"}
                            onChange={(files) => files.length === 0 ? this.img = null : this.img = files[0]} filesLimit={1} showAlerts={false} />
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }
}

class Option extends Component {
    render() {
        return [
            <Grid key={0} item xs={9}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Odpověď"
                    name="optionName"
                    inputRef={text => this.text = text}
                />
            </Grid>,
            <Grid key={1} item xs={3}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    label="Body"
                    name="optionPoints"
                    inputRef={points => this.points = points}
                />
            </Grid>]
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

export default connect(mapStateToProps)(CreateTest);