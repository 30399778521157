import React, { Component } from "react";

import { connect } from "react-redux";
import { load } from "../../actions";

import socket from "../../utils/socket.io";
import formatISOdate from "../../utils/formatISOdate";

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

class TestGrade extends Component {
    constructor() {
        super()
        this.questions = [];
    }

    sendGradesHandle() {
        const answers = [];
        for (let i in this.questions) {
            const question = this.questions[i];
            const answer = {
                question: question.props.id,
                comment: question.comment.value,
                points: parseInt(question.points.value)
            };
            answers.push(answer);
        }

        this.props.dispatch(load("test:grade:send"));
        socket.emit("test:grade:finish", this.props.data?.test?.studentId, this.props.data?.test?.id, ...answers);
        return;
    }


    render() {
        const data = this.props.data;
        const questions = data?.questions ?? [];
        const id = data?.test?.id ?? null;
        const name = data?.test?.name ?? "Test není k dispozici";
        const description = data?.test?.description ?? null;
        const userId = data?.test?.studentId ?? null;

        this.questions = new Array(questions.length);

        return (
            <div className="testForm">
                <h1>{name}</h1>
                {description}
                <p>Test je dostupný do: <b>{formatISOdate(data.test.availability_to)}</b></p>
                {questions.map((question, index) => <Question ref={question => this.questions[index] = question} key={question.id} testId={id}
                    id={question.id} name={question.name} description={question.description} answer={question.answer} image={question.image}
                    points={question.points} type={question.type} options={question.options} dispatch={this.props.dispatch} loading={this.props.loading}
                    userId={userId} />)}
                <Button className="sendAllButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                    onClick={() => this.sendGradesHandle()}>
                    {this.props.loading === "test:grade:send" ? <CircularProgress size={24} /> : "Uložit a ukončit"}
                </Button>
            </div>
        )
    }
}

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedOption: props?.answer?.question_option ?? -1 }
        this.answer = null;
    }

    sendGradeHandle() {
        const answer = {
            question: this.props.id,
            comment: this.comment.value,
            points: parseInt(this.points.value)
        };

        this.props.dispatch(load("test:grade:send:" + this.props.id));
        socket.emit("test:grade", this.props.userId, this.props.testId, answer);
    }

    render() {
        const id = this.props?.id ?? null;
        const name = this.props?.name ?? null;
        const description = this.props?.description ?? null;
        const image = this.props?.image ?? null;
        const points = this.props?.points ?? null;
        const type = this.props?.type ?? null;
        const answer = this.props?.answer ?? null;
        const options = this.props?.options ?? [];

        return (
            <Paper className="testFormQuestion">
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <b>{name}</b>
                    </Grid>
                    <Grid item xs={3}>
                        Maximum bodů: {points}
                    </Grid>
                    <Grid item xs={12}>
                        {description}
                    </Grid>

                    {image !== null ?
                        <Grid item xs={12}>
                            <img className="questionImage" src={image} alt="Question">
                            </img>
                        </Grid> : null}

                    <Grid item xs={12}>
                        {type === "options" ?
                            <FormControl component="fieldset">
                                <RadioGroup value={this.state.selectedOption}>
                                    <FormControlLabel value={-1} control={<Radio disabled />} label="Žádná odpověd" />
                                    {options.map((option) => <FormControlLabel value={option.id} control={<Radio disabled />} label={option.text} />)}
                                </RadioGroup>
                            </FormControl>
                            :
                            <TextField
                                fullWidth
                                disabled
                                variant="outlined"
                                margin="normal"
                                label="Odpověď"
                                value={answer?.text ?? ""}
                                multiline={type === "multiline"}
                            />}
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Komentář"
                            multiline
                            defaultValue={answer?.comment ?? ""}
                            inputRef={comment => this.comment = comment}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Počet bodů"
                            required
                            number
                            type="number"
                            inputRef={points => this.points = points}
                            defaultValue={answer?.points ?? ((answer?.question_option ?? null) === null ? "" : (options.find(option => option.id === answer.question_option)?.points ?? ""))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                            onClick={() => this.sendGradeHandle()}>
                            {this.props.loading === "test:grade:send:" + id ? <CircularProgress size={24} /> : "Uložit"}
                        </Button>
                    </Grid>
                </Grid>


            </Paper>

        )
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
        loading: state.loading
    }
}

export default connect(mapStateToProps)(TestGrade);