import React from "react";

import { connect } from "react-redux";

import Connecting from "./pages/Connecting";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

import "./App.css";

function App({ connection, user, token }) {
  console.log(user);

  if (!connection) { return <Connecting /> }

  if (user === null) {
    if (token !== null) { return <Connecting /> }
    else { return <Login /> }
  }
  else { return <Dashboard /> }
}
function mapStateToProps(state) {
  return {
    token: state.token,
    connection: state.connection,
    user: state.user
  }
}

export default connect(mapStateToProps)(App);