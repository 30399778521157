// Inspired by: https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/menus/SimpleMenu.js

import React, { Component } from "react";

import { connect } from "react-redux";
import { load } from "../../actions";

import socket from "../../utils/socket.io";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from '@material-ui/core/CircularProgress';

function UsersPromote({ dispatch, data, loading }) {
    const users = data?.users ?? [];
    const roles = data?.roles ?? [];

    return (<div>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Uživatelské jméno</TableCell>
                        <TableCell align="left">Jméno a příjmení</TableCell>
                        <TableCell align="left">Role</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (<User dispatch={dispatch} key={user.id} user={user} roles={roles} loading={loading} />))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
    );
}


function editRoleHandle(dispatch, userId, newRole) {
    dispatch(load("users:promote:" + userId));
    socket.emit("users:edit:role", userId, newRole);
}

class User extends Component {
    constructor() {
        super();
        this.state = { open: false }

        this.role = React.createRef();
    }

    render() {
        return (
            <TableRow>
                <TableCell align="left">{this.props.user.username}</TableCell>
                <TableCell align="left">{this.props.user.fullname}</TableCell>
                <TableCell align="left">{this.props.user.role}</TableCell>
                <TableCell align="center">
                    {this.props.user.role === "Student" ?
                        <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                            onClick={() => editRoleHandle(this.props.dispatch, this.props.user.id, "Asistent")}>
                            {this.props.loading === "users:promote:" + this.props.user.id ? <CircularProgress size={24} /> : "Povýšit"}
                        </Button> :
                        this.props.user.role === "Asistent" ?
                            <Button className="tableButton" variant="contained" color="secondary" disabled={this.props.loading ? true : false}
                                onClick={() => editRoleHandle(this.props.dispatch, this.props.user.id, "Student")}>
                                {this.props.loading === "users:promote:" + this.props.user.id ? <CircularProgress size={24} /> : "Degradovat"}
                            </Button> : null}
                </TableCell>
            </TableRow >
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        data: state.data
    }
}

export default connect(mapStateToProps)(UsersPromote);