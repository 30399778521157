import { CONNECT, DISCONNECT } from "../actions";

export default function connection(state = false, action) {
    switch (action.type) {
        case DISCONNECT:
            return false

        case CONNECT:
            return true

        default:
            return state
    }
}