// Inspired by: https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/menus/SimpleMenu.js

import React, { Component } from "react";

import { connect } from "react-redux";
import { load } from "../../actions";

import socket from "../../utils/socket.io";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import CreateUser from "../../components/CreateUser";
import UserRoles from "../../components/UserRoles";

function UsersManage({ dispatch, data, loading }) {
    const users = data?.users ?? [];
    const roles = data?.roles ?? [];

    return (<div>
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="left">Uživatelské jméno</TableCell>
                        <TableCell align="left">Jméno a příjmení</TableCell>
                        <TableCell align="left">Role</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (<User dispatch={dispatch} key={user.id} user={user} roles={roles} loading={loading} />))}
                </TableBody>
            </Table>
        </TableContainer>

        <CreateUser roles={roles} createHandle={createHandle} />

    </div>
    );
}

function deleteHandle(dispatch, userId) {
    dispatch(load("users:edit:delete:" + userId));
    socket.emit("users:delete", userId);
}

function restoreHandle(dispatch, userId) {
    dispatch(load("users:edit:restore:" + userId));
    socket.emit("users:restore", userId);
}

function createHandle(dispatch, username, fullname, password, role) {
    dispatch(load("dashboard"));
    socket.emit("users:create", username, fullname, password, role);
}

function editFullnameHandle(dispatch, userId, newName) {
    dispatch(load("users:edit:fullname:" + userId));
    socket.emit("users:edit:fullname", userId, newName);
}

function editUsernameHandle(dispatch, userId, newName) {
    dispatch(load("users:edit:username:" + userId));
    socket.emit("users:edit:username", userId, newName);
}

function editPasswordHandle(dispatch, userId, newPassword) {
    dispatch(load("users:edit:password:" + userId));
    socket.emit("users:edit:password", userId, newPassword);
}

function editRoleHandle(dispatch, userId, newRole) {
    dispatch(load("users:edit:role:" + userId));
    socket.emit("users:edit:role", userId, newRole);
}

class User extends Component {
    constructor() {
        super();
        this.state = { open: false }

        this.role = React.createRef();
    }

    render() {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ open: !this.state.open })}>
                            {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">{this.props.user.username}</TableCell>
                    <TableCell align="left">{this.props.user.fullname}</TableCell>
                    <TableCell align="left">{this.props.user.role}</TableCell>
                    <TableCell align="center">
                        {this.props.user.disabled
                            ? <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                                onClick={() => restoreHandle(this.props.dispatch, this.props.user.id)}>
                                {this.props.loading === "users:edit:restore:" + this.props.user.id ? <CircularProgress size={24} /> : "Obnovit"}
                            </Button>
                            : <Button className="tableButton" variant="contained" color="secondary" disabled={this.props.loading ? true : false}
                                onClick={() => deleteHandle(this.props.dispatch, this.props.user.id)}>
                                {this.props.loading === "users:edit:delete:" + this.props.user.id ? <CircularProgress size={24} /> : "Zakázat"}
                            </Button>}
                    </TableCell>

                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={this.state.open}>
                            <Table>
                                <TableRow>
                                    <TableCell align="left">Změnit jméno a příjmení:</TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            name="fullname"
                                            label="Jméno a příjmení"
                                            type="fullname"
                                            id="fullname"
                                            required
                                            inputRef={fullname => this.fullname = fullname}
                                        /></TableCell>
                                    <TableCell align="center">
                                        <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                                            onClick={() => editFullnameHandle(this.props.dispatch, this.props.user.id, this.fullname.value)}>
                                            {this.props.loading === "users:edit:fullname:" + this.props.user.id ? <CircularProgress size={24} /> : "Uložit"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Změnit uživatelské jméno:</TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            name="username"
                                            label="Uživatelské jméno"
                                            type="username"
                                            id="username"
                                            autoComplete="username"
                                            required
                                            inputRef={username => this.username = username}
                                        /></TableCell>
                                    <TableCell align="center">
                                        <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                                            onClick={() => editUsernameHandle(this.props.dispatch, this.props.user.id, this.username.value)}>
                                            {this.props.loading === "users:edit:username:" + this.props.user.id ? <CircularProgress size={24} /> : "Uložit"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Změnit heslo:</TableCell>
                                    <TableCell>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            name="password"
                                            label="Heslo"
                                            type="password"
                                            id="password"
                                            autoComplete="password"
                                            required
                                            inputRef={password => this.password = password}
                                        /></TableCell>
                                    <TableCell align="center">
                                        <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                                            onClick={() => editPasswordHandle(this.props.dispatch, this.props.user.id, this.password.value)}>
                                            {this.props.loading === "users:edit:password:" + this.props.user.id ? <CircularProgress size={24} /> : "Uložit"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Změnit roli:</TableCell>
                                    <TableCell>
                                        <UserRoles ref={this.role} user={this.props.user} roles={this.props.roles} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                                            onClick={() => editRoleHandle(this.props.dispatch, this.props.user.id, this.role.current.state.role)}>
                                            {this.props.loading === "users:edit:role:" + this.props.user.id ? <CircularProgress size={24} /> : "Uložit"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        data: state.data
    }
}

export default connect(mapStateToProps)(UsersManage);