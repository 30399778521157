import React, { Component } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class RoleSelect extends Component {
    constructor(props) {
        super(props)
        this.state = { role: props.user.role }
    }

    handleChange(e) {
        this.setState({ role: e.target.value });
    };

    render() {
        return (
            <div>
                <FormControl className="roleSelect">
                    <InputLabel>Role</InputLabel>
                    <Select
                        value={this.state.role}
                        onChange={(e) => this.handleChange(e)}
                    >
                        {this.props.roles.map((role) => (
                            <MenuItem key={role} value={role}>{role}</MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </div>
        );
    }
}