import ioConnect from "socket.io-client";
import { connect, disconnect, tokenUpdate, loginError, userUpdate, loadError, setView, raiseSnackbar } from "../actions";
import store from "../store";


const socket = ioConnect();

socket.emitOrig = socket.emit

socket.emit = (event, ...args) => {
    console.log("socket-send-" + event)
    console.log(args)
    socket.emitOrig(event, ...args)
}

socket.onLog = (event, fn) => {
    socket.on(event, (...args)  => {
        console.log("socket-receive-" + event)
        console.log(args)
        fn(...args)
    })
}

socket.onLog("connect", function () {
    store.dispatch(connect());

    const state = store.getState()
    if (state.user === null && state.token !== null) {
        socket.emit("login:token", state.token);
    }
});

socket.onLog("disconnect", function () {
    store.dispatch(disconnect());
});

socket.onLog("token:update", function (token, expiration) {
    store.dispatch(tokenUpdate(token, expiration));
});

socket.onLog("user:update", function (user) {
    store.dispatch(userUpdate(user));
});

socket.onLog("view:update", function (view, params, data) {
    store.dispatch(setView(view, params, data));
});

socket.onLog("success:dashboard", function (reason) {
    store.dispatch(raiseSnackbar("success", reason));
});

socket.onLog("error:dashboard", function (reason) {
    store.dispatch(raiseSnackbar("error", reason));
    store.dispatch(loadError(reason));
});

socket.onLog("error:login", function (reason) {
    store.dispatch(loginError(reason));
});

export default socket;
