import React from "react";

import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';

import ChangePassword from "../../components/ChangePassword";
import Profile from "../../components/Profile";

function UsersActual() {
    return (
        <Grid container spacing={2} alignItems="center" justify="space-evenly">
            <Grid item xs={12} md={6}>
                <Profile />
            </Grid>
            <Grid item xs={12} md={6}>
                <ChangePassword />
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {
        data: state.data
    }
}

export default connect(mapStateToProps)(UsersActual);