import { CONNECT, SET_VIEW, USER_UPDATE } from "../actions";

import history from "../utils/history";
import socket from "../utils/socket.io";

function setTitle(view) {
    switch (view) {
        case "/test/fill":
            document.title = "Testinator - vyplňování"
            break;

        case "/test/view":
            document.title = "Testinator - výsledky"
            break;

        case "/test/grade":
            document.title = "Testinator - opravování"
            break;

        case "/tests/actual":
            document.title = "Testinator - aktuální"
            break;

        case "/tests/history":
            document.title = "Testinator - historie"
            break;

        case "/tests/registration":
            document.title = "Testinator - registrace"
            break;

        case "/tests/confirmation":
            document.title = "Testinator - potvrzování registrací"
            break;

        case "/tests/grade":
            document.title = "Testinator - k opravení"
            break;

        case "/tests/manage":
            document.title = "Testinator - správa testů"
            break;

        case "/users/actual":
            document.title = "Testinator - profil"
            break;

        case "/users/manage":
            document.title = "Testinator - správa uživatelů"
            break;

        default:
            document.title = "Testinator"
    }
}

export default function view(state = "/", action) {
    switch (action.type) {
        case CONNECT:
            return "/";

        case USER_UPDATE:
            if (action.user !== null && state === "/") {
                // logged in
                const urlParams = {};
                const paramsParser = new URLSearchParams(history.location.search).entries()
                for (let entry = paramsParser.next(); !entry.done; entry = paramsParser.next()) {
                    const [key, value] = entry.value
                    urlParams[key] = value;
                }

                socket.emit("view:switch", history.location.pathname, urlParams);
            }
            return state;

        case SET_VIEW:
            setTitle(action.view);
            const paramsString = Object.keys(action.params)
                .map(key => `${key}=${action.params[key]}`)
                .join('&');
            console.log(paramsString)
            console.log(history.location.pathname + history.location.search)
            if (action.view !== history.location.pathname || (history.location.search === "" ? paramsString !== "" : "?" + paramsString !== history.location.search)) {
                history.push(action.view + "?" + paramsString);
            }
            return action.view;

        default:
            return state
    }
}