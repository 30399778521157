import React, { Component } from "react";

import { connect } from "react-redux";

import { raiseSnackbar } from "../actions";

import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';

import UserRoles from "./UserRoles";

class CreateUser extends Component {
    constructor() {
        super();
        this.state = { open: false };

        this.role = React.createRef();
    }


    handleClickOpen() {
        this.setState({ open: true });
    };

    handleClose(created) {
        if (created) {
            if (this.username.value === "" || this.password.value === "" || this.fullname.value === "" || this.role.current.state.role === "") {
                this.props.dispatch(raiseSnackbar("error", "Nejsou vyplněna všechna pole."));
                return;
            }
            this.props.createHandle(this.props.dispatch, this.username.value, this.fullname.value, this.password.value, this.role.current.state.role);
        }
        this.setState({ open: false });
    };

    render() {
        return (<div>
            <Fab id="users:edit:create:" color="primary" className="fab" disabled={this.props.loading ? true : false}
                onClick={() => { this.handleClickOpen() }}><AddIcon /></Fab>
            <Dialog open={this.state.open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Tvorba nového uživatele</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="fullname"
                        label="Celé jméno"
                        name="fullname"
                        autoComplete="fullname"
                        autoFocus
                        inputRef={fullname => this.fullname = fullname}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Uživatelské jméno"
                        name="username"
                        autoComplete="username"
                        inputRef={username => this.username = username}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Heslo"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        inputRef={password => this.password = password}
                    />
                    <UserRoles
                        user={{ role: "" }}
                        roles={this.props.roles}
                        ref={this.role} />
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.props.loading ? true : false} onClick={() => this.handleClose(false)} color="primary">
                        Zrušit
                </Button>
                    <Button disabled={this.props.loading ? true : false} onClick={() => this.handleClose(true)} color="primary">
                        {this.props.loading ? <CircularProgress size={24} /> : "Potvrdit"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

export default connect(mapStateToProps)(CreateUser);