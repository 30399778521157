import React, { Component } from "react";

import { connect } from "react-redux";
import { load } from "../../actions";

import socket from "../../utils/socket.io";
import formatISOdate from "../../utils/formatISOdate";

import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

class TestFill extends Component {
    constructor() {
        super()
        this.questions = [];
    }

    sendAnswersHandle(testId, dispatch) {
        const answers = []
        for (let i in this.questions) {
            const question = this.questions[i];
            const answer = { question: question.props.id };
            if (question.answer !== null) {
                answer.text = question.answer.value;
            }
            else {
                answer.question_option = question.state.selectedOption === -1 ? null : question.state.selectedOption;
            }
            answers.push(answer);
        }

        dispatch(load("tests:fill:send"));
        socket.emit("test:answer", testId, ...answers);
        return;
    }


    render() {
        const data = this.props.data;
        const questions = data?.questions ?? [];
        const id = data?.test?.id ?? null;
        const name = data?.test?.name ?? "Test není k dispozici";
        const description = data?.test?.description ?? null;

        this.questions = new Array(questions.length);

        return (
            <div className="testForm">
                <h1>{name}</h1>
                {description}
                <p>Test je dostupný do: <b>{formatISOdate(data.test.availability_to)}</b></p>
                {questions.map((question, index) => <Question ref={question => this.questions[index] = question} key={question.id} testId={id}
                    id={question.id} name={question.name} description={question.description} answer={question.answer} image={question.image}
                    points={question.points} type={question.type} options={question.options} dispatch={this.props.dispatch} loading={this.props.loading} />)}
                <Button className="sendAllButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                    onClick={() => this.sendAnswersHandle(id, this.props.dispatch)}>
                    {this.props.loading === "tests:fill:send" ? <CircularProgress size={24} /> : "Uložit vše"}
                </Button>
            </div>
        )
    }
}

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedOption: props?.answer?.question_option ?? -1 }
        this.answer = null;
    }

    handleChange = (event) => {
        this.setState({ selectedOption: parseInt(event.target.value) });
    };

    sendAnswerHandle(testId, questionId, dispatch) {
        const answer = { question: questionId };
        if (this.answer !== null) {
            answer.text = this.answer.value;
        }
        else {
            answer.question_option = this.state.selectedOption === -1 ? null : this.state.selectedOption;
        }

        dispatch(load("tests:fill:send:" + questionId));
        socket.emit("test:answer", testId, answer);
    }

    render() {
        const testId = this.props?.testId ?? null;
        const id = this.props?.id ?? null;
        const name = this.props?.name ?? null;
        const description = this.props?.description ?? null;
        const image = this.props?.image ?? null;
        const points = this.props?.points ?? null;
        const type = this.props?.type ?? null;
        const answer = this.props?.answer ?? null;
        const options = this.props?.options ?? [];

        return (
            <Paper className="testFormQuestion">
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <b>{name}</b>
                    </Grid>
                    <Grid item xs={3}>
                        Maximum bodů: {points}
                    </Grid>
                    <Grid item xs={12}>
                        {description}
                    </Grid>

                    {image !== null ?
                        <Grid item xs={12}>
                            <img className="questionImage" src={image} alt="Question" >
                            </img>
                        </Grid> : null}

                    <Grid item xs={12}>
                        {type === "options" ?
                            <FormControl component="fieldset">
                                <RadioGroup value={this.state.selectedOption} onChange={this.handleChange}>
                                    <FormControlLabel value={-1} control={<Radio color="primary" />} label="Žádná odpověd" />
                                    {options.map((option) => <FormControlLabel value={option.id} control={<Radio color="primary" />} label={option.text} />)}
                                </RadioGroup>
                            </FormControl>
                            :
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Odpověď"
                                defaultValue={answer?.text ?? ""}                                
                                multiline={type === "multiline"}
                                inputRef={answer => this.answer = answer}
                            />}
                    </Grid>
                    <Grid item xs={12}>
                        <Button className="tableButton" variant="contained" color="primary" disabled={this.props.loading ? true : false}
                            onClick={() => this.sendAnswerHandle(testId, id, this.props.dispatch)}>
                            {this.props.loading === "tests:fill:send:" + id ? <CircularProgress size={24} /> : "Uložit"}
                        </Button>
                    </Grid>
                </Grid>


            </Paper>

        )
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
        loading: state.loading
    }
}

export default connect(mapStateToProps)(TestFill);