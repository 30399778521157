// Inspired by: https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/sign-in

import React, { Component } from "react";

import { connect } from "react-redux";
import { loginErrorClose, load } from "../actions";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import socket from "../utils/socket.io";
class SignIn extends Component {
    loginHandle(e) {
        e.preventDefault();
        this.props.dispatch(load("login"));

        socket.emit(
            "login:credentials",
            this.username.value,
            this.password.value)
    }

    errorClose() {
        this.props.dispatch(loginErrorClose());
    }

    render() {
        return (
            <Container className="login" component="main" maxWidth="xs">
                <Avatar className="avatar" />
                <h1>Přihlášení</h1>
                <form onSubmit={(e) => this.loginHandle(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Uživatelské jméno"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        inputRef={username => this.username = username}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Heslo"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        inputRef={password => this.password = password}
                    />
                    {this.props.loading === "login"
                        ?
                        <Button
                            disabled
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"><CircularProgress size={24} />
                        </Button>
                        :
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"> Přihlásit
                    </Button>}
                </form>
                <Snackbar open={this.props.loginAlert !== null} onClose={() => this.errorClose()}>
                    <Alert onClose={() => this.errorClose()} severity="error" elevation={6} variant="filled">
                        {this.props.loginAlert}
                    </Alert>
                </Snackbar>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        loginAlert: state.loginAlert
    }
}

export default connect(mapStateToProps)(SignIn)