export const CONNECT = "CONNECT";
export const connect = () => ({ type: CONNECT });
export const DISCONNECT = "DISCONNECT";
export const disconnect = () => ({ type: DISCONNECT });

// Received token form server
export const TOKEN_UPDATE = "TOKEN_UPDATE";
export const tokenUpdate = (token, expiration) => ({
    type: TOKEN_UPDATE,
    token,
    expiration
});

// Token in local storage changed
export const TOKEN_CHANGE = "TOKEN_CHANGE";
export const tokenChange = (token) => ({
    type: TOKEN_CHANGE,
    token
})

export const LOGIN_ERROR = "LOGIN_ERROR";
export const loginError = (reason) => ({
    type: LOGIN_ERROR,
    reason
});

export const USER_UPDATE = "USER_UPDATE";
export const userUpdate = (user) => ({
    type: USER_UPDATE,
    user
});

export const LOAD = "LOAD";
export const load = (callerId) => ({
    type: LOAD,
    callerId
});

export const LOAD_ERROR = "LOAD_ERROR";
export const loadError = (reason) => ({
    type: LOAD_ERROR,
    reason
});

export const SET_VIEW = "SET_VIEW";
export const setView = (view, params, data) => ({
    type: SET_VIEW,
    view,
    params,
    data
});

export const LOGIN_ERROR_CLOSE = "LOGIN_ERROR_CLOSE";
export const loginErrorClose = () => ({ type: LOGIN_ERROR_CLOSE });

export const RAISE_SNACKBAR = "RAISE_SNACKBAR";
export const raiseSnackbar = (severity, text) => ({
    type: RAISE_SNACKBAR,
    severity,
    text
});

export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const closeSnackbar = () => ({ type: CLOSE_SNACKBAR });